<template>
	<div class="industry">
		<frame :top1="top1" :top2="top2">
		<div class="titles1" style="margin-top: 10px;margin-right: 62%;">店铺>行业管理</div>
			<list :titles='titles' :flag='flag' :contentC='datas' @del='refresh()' @add='refresh()'>
				
				<topadd @add='refresh()' :flag='flag'></topadd>
			</list>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import list from '../template/List.vue';
	import topadd from '../template/ShopSearch.vue';
	export default {
		name: 'industry',
		data() {
			return {
				top1: '2-2',
				top2: ['2'],
				titles:['选择','商家编号','行业名称','已有商城','操作'],
				flag:4,
				datas: null
			}
		},
		created() {
			let token = this.$storage.getLocal('token');
			let i_name = '';
			let limit = 10;
			let page = 1;
			this.$request.selIndustryData({token,page,limit,i_name}).then(res=>{
				// console.log(res);
				if(res.code==0){
					this.datas = res.data;
				}
			})
		},
		components: {
			frame,
			list,
			topadd
		},
		methods: {
			refresh(){
				let token = this.$storage.getLocal('token');
				let i_name = '';
				let limit = 10;
				let page = 1;
				this.$request.selIndustryData({token,page,limit,i_name}).then(res=>{
					// console.log(res);
					if(res.code==0){
						this.datas = res.data;
					}
				})
			}
		}
	}
</script>


<style scoped="scoped">
.industry
{
background-color: rgb(245,247,249);
height: 800px;
}
</style>
